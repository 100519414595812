import React from "react";
import { Card, Container } from "react-bootstrap";

export const ValsTcs = () => {
  return (
    <section>
      <Container className="my-5 card border-1 px-5 py-4">
        <h1 className="mb-4 text-uppercase section-text-header">
          Terms and Conditions for “Fa Borga Money Gye Wo Points” Promotion
        </h1>
        <Card className="mb-4 border-0 ">
          <Card.Body>
            <div className="my-5">
              <h2 className="point-header">1. General Terms</h2>
              <p>
                1.1. The promotional campaign ("Promotion") is organized by
                Borga Money ("Organizer").
              </p>
              <p>
                1.2. By participating in this Promotion, customers agree to be
                bound by these terms and conditions of the Promotion.
              </p>
              <p>
                1.3. The Promotion is only valid during the specified period and
                is subject to availability.
              </p>
              <p>
                {" "}
                1.4. The Organizer reserves the right to modify, suspend, or
                terminate the Promotion at any time without prior notice.
              </p>
            </div>
            <div className="my-5">
              <h2 className="point-header">
                2. Promo Details 1: Airtime Reward
              </h2>
              <p>
                2.1. Customers who send a one-time minimum of 200 GBP within the
                promotional period (14th February 2025, from 1:00 AM BST to 7:00
                PM BST) will be eligible to receive up to 20 Ghana cedis airtime
                for one recipient of their choice.
              </p>
              <p>
                2.2. Sender must provide details using a designated Google Form,
                including the phone number, network, and name of the Recipient.
              </p>
            </div>
            <div className="my-5">
              <h2 className="point-header">
                3. Promo Details 2: Spa Voucher Giveaway
              </h2>
              <p>
                3.1. Customers who send up to 2000 GBP between 7th February and
                13th February (until 11:59 PM BST) will qualify for a chance to
                win a spa voucher for their loved one.{" "}
              </p>
              <p>3.2. A total of three (3) spa vouchers will be awarded.</p>
              <p>
                3.4. Winners will be chosen at random by the Organizer and will
                be contacted via the details provided during their transaction
                process.{" "}
              </p>
            </div>
            <div className="my-5">
              <h2 className="point-header">4. Eligibility </h2>
              <p>
                4.1. This Promotion is open to customers who complete qualifying
                transactions during the promotional period.
              </p>
              <p>
                4.2. The Promotion applies only to transactions that meet the
                stated amount requirements.
              </p>
              <p>
                4.3. Employees of Borga Money App, affiliates, and their
                immediate family members are not eligible to participate.
              </p>
            </div>
            <div className="my-5">
              <h2 className="point-header">
                5. Disbursement and Prize Conditions{" "}
              </h2>
              <p>
                5.1. Airtime rewards will be disbursed to the recipient details
                provided by the customer. The Organizer will not be responsible
                for incorrect details submitted by customers.
              </p>
              <p>
                5.2. Spa vouchers are non-transferable and cannot be exchanged
                for cash or any other alternative.
              </p>
              <p>
                5.3. If a winner fails to respond within 48 hours of
                notification, the Organizer reserves the right to select an
                alternate winner.
              </p>
            </div>

            <div className="my-5">
              <h2 className="point-header">6. Miscellaneous</h2>
              <p>
                6.1. The Organizer is not responsible for any technical issues,
                network failures, or delays in processing transactions that may
                affect eligibility.
              </p>
              <p>
                6.2. Any attempt to manipulate or abuse the Promotion will
                result in disqualification.
              </p>
              <p>
                6.3. The Organizer’s decision on all matters relating to this
                Promotion shall be final and binding.
              </p>
            </div>
            <div>
              Customers acknowledge and accept these Terms and Conditions when
              participating in this Promotion.
            </div>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};
