import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import { TermsAndConditions } from './TermsAndConditions';
import Back2School from './back2school';
import ReferralProgramTerms from './referrals';
import { ValsTcs } from './valsTsc';

function TCS() {
  const [activeKey, setActiveKey] = useState('general_tcs');

  const handleSelect = (eventKey:any) => {
    setActiveKey(eventKey);
  };

  return (
    <Container className='my-5'>
      <Nav variant="pills" activeKey={activeKey} onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="general_tcs" className='point-header'>General Ts & Cs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="referral_tsc" className='point-header' > Referrals Ts & Cs </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="back2school_tsc" className='point-header'>
            Back To School Ts & Cs
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="val_tsc" className='point-header' > Vals Day Ts & Cs </Nav.Link>
        </Nav.Item>
      </Nav>

      <div style={{ marginTop: '20px' }}>
        {activeKey === 'general_tcs' && <div><TermsAndConditions/></div>}
        {activeKey === 'referral_tsc' && <div><ReferralProgramTerms/></div>}
        {activeKey === 'back2school_tsc' && <div><Back2School/></div>}
        {activeKey === 'val_tsc' && <div><ValsTcs/></div>}
      </div>
    </Container>
  );
}

export default TCS;
